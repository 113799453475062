let linkElem = null;

class HistoryStateHelper {
	static _buildQuery(queryParts) {
		const queryStrings = [];
		for (const q in queryParts) {
			queryStrings.push(queryParts[q] !== null ? q + '=' + queryParts[q] : q);
		}

		return queryStrings.join('&');
	}

	static _parseQuery(url) {
		if (!linkElem) {
			linkElem = document.createElement('a');
		}
		linkElem.href = url ? url : window.location.href;

		const search     = linkElem.search.replace('/?', '').replace('?', '').split('&');
		const queryParts = {};

		for (let s = 0; s < search.length; s++) {
			if (search[s].length) {
				const queryPart          = search[s].split('=');
				queryParts[queryPart[0]] = queryPart.length === 2 ? queryPart[1] : null;
			}
		}

		return queryParts;
	}

	static _replaceQuery(url, query) {
		if (!url) {
			url = window.location.href;
		}

		if (!linkElem) {
			linkElem = document.createElement('a');
		}

		if (linkElem.search.startsWith('/?')) {
			url = url.replace(linkElem.search, '/?' + query);
		} else if (linkElem.search.startsWith('?')) {
			url = url.replace(linkElem.search, '?' + query);
		} else {
			url = url.replace(linkElem.hash, '') + '?' + query + linkElem.hash;
		}

		return url;
	}

	static getValue(key, defaultValue = null) {
		return HistoryStateHelper.hasKey(key) ? window.history.state[key] : defaultValue;
	}

	static hasKey(key) {
		return window.history && window.history.state && typeof window.history.state[key] !== 'undefined';
	}

	static removeKey(key) {
		if (HistoryStateHelper.hasKey(key)) {
			const currentHistoryState = window.history.state;
			delete currentHistoryState[key];
			window.history.replaceState(currentHistoryState, '');
		}
	}

	/**
	 * @param {String} key
	 * @param {String} value
	 * @param {String} title
	 * @param {String} url
	 */
	static setValue(key, value, title = '', url = null) {
		if (window.history) {
			const currentHistoryState = window.history.state || {};

			if (key !== null) {
				currentHistoryState[key] = value;
			}

			window.history.replaceState(currentHistoryState, title, url);
		}
	}

	static urlContainsGetParam(url, key, value = null) {
		const queryParts  = HistoryStateHelper._parseQuery(url);
		let containsParam = typeof queryParts[key] !== 'undefined';

		if (containsParam && value !== null) {
			containsParam = queryParts[key] === value;
		}

		return containsParam;
	}

	static urlReplaceState(key, value = null) {
		window.history.replaceState(window.history.state, document.title, HistoryStateHelper.urlSetGetParam(null, key, value));
	}

	static urlSetGetParam(url, key, value = null) {
		const queryParts = HistoryStateHelper._parseQuery(url);
		if (value === false) {
			delete queryParts[key];
		} else {
			queryParts[key] = value;
		}

		return HistoryStateHelper._replaceQuery(url, HistoryStateHelper._buildQuery(queryParts));
	}
}

export default HistoryStateHelper;